
import React, { createContext, useState, useEffect } from 'react';
import { initializeApp, getApp, getApps } from "firebase/app";
import { getDatabase, ref, query, orderByChild,equalTo, get, onValue } from "firebase/database";
import { initializeAuth, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPhoneNumber, PhoneAuthProvider, RecaptchaVerifier, unlink, updatePhoneNumber, linkWithPhoneNumber, browserLocalPersistence, browserPopupRedirectResolver } from "firebase/auth";
import { getStorage, ref as stRef } from "firebase/storage";
import { getReactNativePersistence } from './react-native-persistance';
import { current } from '@reduxjs/toolkit';
import { Alert, Linking, View, Text, Button } from 'react-native';
import packageJson from '../../../mobile-app/package.json';
import { initializeAppCheck,CustomProvider} from "firebase/app-check";
import axios from 'axios';


const FirebaseContext = createContext(null);

let firebase = {
    app: null,
    database: null,
    auth: null,
    storage: null,
}   

const createFullStructure = (app, db, auth, storage, config) => {
    return {
        app: app,
        config: config,
        database: db,
        auth: auth,
        storage: storage,
        authRef:getAuth,
        googleProvider:new GoogleAuthProvider(),
        appleProvider:new OAuthProvider('apple.com'),
        phoneProvider:new PhoneAuthProvider(auth),          
        RecaptchaVerifier: RecaptchaVerifier,
        signInWithPhoneNumber: signInWithPhoneNumber,   
        updatePhoneNumber:updatePhoneNumber,
        unlink: unlink,
        googleCredential: (idToken, accessToken)=> GoogleAuthProvider.credential(idToken, accessToken),    
        linkWithPhoneNumber: linkWithPhoneNumber,
        mobileAuthCredential: (verificationId,code) => PhoneAuthProvider.credential(verificationId, code),           
        usersRef: ref(db, 'users'),
        bookingRef: ref(db, 'bookings'),
        cancelreasonRef: ref(db, 'cancel_reason'),
        settingsRef: ref(db, 'settings'),
        smtpRef: ref(db, "smtpdata"),
        smsRef: ref(db, "smsConfig"),
        carTypesRef: ref(db, 'cartypes'),
        carTypesEditRef:(id) => ref(db, "cartypes/"+ id),
        carDocImage:(id) =>  stRef(storage, `cartypes/${id}`),     
        promoRef: ref(db, 'promos'),
        promoEditRef:(id) => ref(db, "promos/"+ id),
        notifyRef: ref(db,"notifications/"),
        notifyEditRef:(id) => ref(db, "notifications/"+ id),
        addressRef: (uid,id) =>  ref(db, "savedAddresses/"+ uid + "/" + id),
        addressEditRef:(uid) => ref(db, "savedAddresses/"+ uid),
        singleUserRef:(uid) => ref(db, "users/" + uid),
        profileImageRef:(uid) => stRef(storage,`users/${uid}/profileImage`),
        verifyIdImageRef:(uid) => stRef(storage,`users/${uid}/verifyIdImage`),
        bookingImageRef:(bookingId,imageType) => stRef(storage,`bookings/${bookingId}/${imageType}`),
        driversRef: query(ref(db, "users"), orderByChild("usertype"), equalTo("driver")),
        driverDocsRef:(uid) => stRef(storage,`users/${uid}/license`),       
        driverDocsRefBack:(uid) => stRef(storage,`users/${uid}/licenseBack`),         
        singleBookingRef:(bookingKey) => ref(db, "bookings/" + bookingKey),
        requestedDriversRef:(bookingKey ) => ref(db, "bookings/" + bookingKey  + "/requestedDrivers"),
        referralIdRef:(referralId) => query(ref(db, "users"), orderByChild("referralId"), equalTo(referralId)),
        trackingRef: (bookingId) => ref(db, 'tracking/' + bookingId),
        tasksRef:() => query(ref(db, 'bookings'), orderByChild('status'), equalTo('NEW')),
        singleTaskRef:(uid,bookingId) => ref(db, "bookings/" + bookingId  + "/requestedDrivers/" + uid),
        bookingListRef:(uid,role) => 
            role == 'customer'? query(ref(db, 'bookings'), orderByChild('customer'), equalTo(uid)):
                (role == 'driver'? 
                    query(ref(db, 'bookings'), orderByChild('driver'), equalTo(uid))
                    :
                    (role == 'fleetadmin'? 
                        query(ref(db, 'bookings'), orderByChild('fleetadmin'), equalTo(uid))
                        : ref(db, 'bookings')
                    )
                ),
        chatRef:(bookingId) => ref(db, 'chats/' + bookingId + '/messages'),
        withdrawRef: ref(db, 'withdraws/'),
        languagesRef: ref(db, "languages"),
        languagesEditRef:(id) => ref(db, "languages/"+ id),
        walletHistoryRef:(uid) => ref(db, "walletHistory/" + uid),  
        userNotificationsRef:(uid) =>  ref(db, "userNotifications/"+ uid),
        userRatingsRef:(uid) =>  ref(db, "userRatings/"+ uid),
        carsRef:(uid,role) => role == 'driver'? 
            query(ref(db, 'cars'), orderByChild('driver'), equalTo(uid))
            :(role == 'fleetadmin'? 
                query(ref(db, 'cars'), orderByChild('fleetadmin'), equalTo(uid))
                : ref(db, 'cars')
            ),
        carAddRef: ref(db, "cars"),
        carEditRef:(id) => ref(db, "cars/"+ id),
        carImage:(id) => stRef(storage,`cars/${id}`),   
        allLocationsRef: ref(db, "locations"),
        userLocationRef:(uid) => ref(db, "locations/"+ uid),
        sosRef: ref(db, 'sos'),
        editSosRef:(id) => ref(db, "sos/" +id),
        complainRef: ref(db, 'complain'),
        editComplainRef:(id) => ref(db, "complain/" +id)
    }
}

function test(currentVersion, appVersion){

}

const FirebaseProvider  = ({ config, children, AsyncStorage }) => {
    let app, auth, database, storage;

    if (!getApps().length) {
        try {
            app = initializeApp(config);

            if (typeof document !== 'undefined') {
                auth = initializeAuth(app, {
                    persistence: browserLocalPersistence,
                    popupRedirectResolver: browserPopupRedirectResolver,
                });
            }
            else{
                auth = initializeAuth(app, {
                    persistence: getReactNativePersistence(AsyncStorage),
                });
            }
            database = getDatabase(app);
            storage = getStorage(app);

        } catch (error) {
            console.log("Error initializing app: " + error);
        }
    } else {
        app = getApp();
        auth = getAuth(app);
        database = getDatabase(app);
        storage = getStorage(app);
    }

    firebase = createFullStructure(app, database, auth, storage, config);
    const [isAlertOpened, setAlertOpened] = useState(false);


    //;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;


    //InitAppCheck();

    /*exports.generateAppCheckToken = functions.https.onRequest(async (req, res) => {
    const appCheckToken = await admin.appCheck().createToken(YOUR_APP_ID, {
        ttlMillis: 3600000, // 1 hour in milliseconds
    });

    res.json({
        token: appCheckToken.token,
        expireTimeMillis: appCheckToken.expireTimeMillis,
    });
    });
*/
  

useEffect(() => {
    const fetchAppCheckToken = async () => {
      try {
        let url = 'https://smarttaxi.pro/request_appcheck_token';
        
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Response status:', response.status);
        //console.log('Response headers:', response.headers);
    
        //const responseText = await response.text();
        //console.log('Response text:', responseText);
        const { err,token, tokenId, expiration } = await response.json();
        var exp = 0;

        if (expiration == null || expiration == 0){
            exp = token.ttlMillis / 1000;
        }

        const appCheckCustomProvider = {
          getToken: () => {
            return new Promise((resolve, _reject) => {
              const appCheckToken = {
                token: tokenId,
                expireTimeMillis: exp == 0 ? expiration * 1000 : exp 
              };
              resolve(appCheckToken);
            });
          }
        };

        /*const appCheck = initializeAppCheck({
            provider: appCheckCustomProvider,
            isTokenAutoRefreshEnabled: true
          });*/

        /*const appCheckCustomProvider = {
            token: tokenId,
            expireTimeMillis: expiration * 1000
          };*/

          /*const appCheckProvider = fb.appCheck().newReactNativeFirebaseAppCheckProvider()
          appCheckProvider.configure({
            android: {
              provider: __DEV__ ? 'debug' : 'playIntegrity',
              debugToken: 'YOUR_DEBUG_TOKEN',
            },
            apple: {
              provider: __DEV__ ? 'debug' : 'appAttestWithDeviceCheckFallback',
              debugToken: 'YOUR_DEBUG_TOKEN',
            },
          })*/ 

          //fb.appCheck().initializeAppCheck({ provider: appCheckCustomProvider, isTokenAutoRefreshEnabled: true })



      } catch (error) {
        console.error('Error fetching App Check token:', error);
      }
    };

    fetchAppCheckToken();
  }, []);



    //firebasee.auth().settings.appVerificationDisabledForTesting = true;
    //62:9d:e4:7e:1f:cb:09:19:0b:f1:d3:a8:ac:42:32:c9:11:49:d2:55:08:19:a7:05:6f:19:84:b7:71:ce:cb:39
    //
    //86:ef:08:62:a6:fb:6f:62:0e:69:04:94:55:12:23:6f:84:03:69:ae:3b:f1:21:0c:60:64:bd:be:02:25:ae:d7

    const getVersionLink = ref(database, 'currentVersionLink');
    /* version controller */
    /*const showCustomAlert = (isOlderVersion) => {
        setAlertOpened(true);
        Alert.alert(
            'Попередження',
            'Версія програми застаріла. Будь ласка, оновіть додаток.',
            [
                {
                    text: 'Оновити' ,
                    onPress: () => {
                        setAlertOpened(false);
                        const fetchData = () => {
                            get(getVersionLink).then((snapshot) => {
                                const fetched = snapshot.val();

                                console.log(fetched)
                                isOlderVersion ? showCustomAlert(currentVersion > appVersion) : null;
                                if (fetched != null && fetched != '' && fetched != ' ') {
                                    Linking.openURL(fetched);
                                }
                            });
                        };

                        fetchData();
                    }
                },
            ],
            { cancelable: false }
        );
    };*/

    const[currentVersion, setCurrentVersion] = useState(null);
    const getData = ref(database, 'currentVersion');
    const appVersion = packageJson.version; 

    /*useEffect(() => {
        const intervalId = setInterval(() => {
            get(getData).then((snapshot) => {
                const fetched = snapshot.val();
                setCurrentVersion(fetched);
                if (fetched > appVersion) {
                    if (!isAlertShowed) {
                        showCustomAlert(currentVersion > appVersion);
                    }
                }
            });

        }, 500);
    
        return () => clearInterval(intervalId);
    }, []);*/

    /*useEffect(() => {
        const fetchData = () => {
            get(getData).then((snapshot) => {
                const fetched = snapshot.val();
                setCurrentVersion(fetched);
            });

            onValue(getData, (snapshot) => {
                const fetched = snapshot.val();
                setCurrentVersion(fetched);


                if (fetched != null) {

                    if (fetched > appVersion) {
                        console.log('[ALERT] App version is older then current, need update. Current version is - ', fetched, ' > ', appVersion);

                        //showCustomAlert(fetched > appVersion);*/
                        
                        /*const intervalId = setInterval(() => {
                        
                            showCustomAlert(fetched > appVersion);

                        }, 2000);*//*
                        
                    } else {
                        console.log('[ALERT] Current version == app version', ' (', fetched, ' == ', appVersion, ') ');
                    }
                }
            });
        };

        //fetchData();
        

        return () => getData.off('currentVersion', fetchData)
    }, []);*/

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    )
}

export {
    firebase,
    FirebaseProvider,
    FirebaseContext
}