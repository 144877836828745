export const FirebaseConfig = {
	"projectId": "taxi-518b2",
	"appId": "1:713832990602:web:95a02f9db5428dfe382a73",
	"databaseURL": "https://taxi-518b2-default-rtdb.firebaseio.com",
	"storageBucket": "taxi-518b2.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAFICoOjg1QbSnKZprSKe2ZK1XdJNFhxoo",
	"authDomain": "taxi-518b2.firebaseapp.com",
	"messagingSenderId": "713832990602",
	"measurementId": "G-XKJZYMXTVS"
};