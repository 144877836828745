import { SET_PAID_WAIT_TOTAL_TIME, SET_PAID_STAYING_ACTIVE } from '../actions/paidWaitActions';

export const INITIAL_STATE = {
    paidWaitTotalTime: 0,
    isPaidStayingActive: false,
};

export const paidWaitReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAID_WAIT_TOTAL_TIME:
            return {
                ...state,
                paidWaitTotalTime: action.payload,
            };
        case SET_PAID_STAYING_ACTIVE:
            return {
                ...state,
                isPaidStayingActive: action.payload,
            };
        default:
            return state;
    }
};
