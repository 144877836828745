export const FareCalculator = (distance,time,rateDetails,instructionData, decimal)=>{  
    let baseDistCost = (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance));

    let baseTimeCost = ((parseFloat(rateDetails.rate_per_hour) / 60) * ((parseFloat(time) / 60)));

    let baseCalculated = baseDistCost + baseTimeCost;

    if (distance < 1.0) {
        baseCalculated = 0;
    }

    if(rateDetails.base_fare>0){
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if(instructionData && instructionData.parcelTypeSelected){
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if(instructionData && instructionData.optionSelected){
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }

    let total = parseFloat(rateDetails.min_fare) + baseCalculated;//baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);

    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        convenienceFee = rateDetails.convenience_fees;
    }else{
        convenienceFee = (total*parseFloat(rateDetails.convenience_fees)/100);
    }
    let grand = total;// + convenienceFee;

    console.log('BASE total cost is - ', total);
    console.log('BASE fee - ', convenienceFee);
    console.log('BASE calculated - ', baseCalculated);
    console.log('BASE time - ', parseFloat(time));
    console.log('BASE dist - ', parseFloat(distance));

    return {
        totalCost:parseFloat(total.toFixed(decimal)),
        grandTotal:parseFloat(grand.toFixed(decimal)),
        convenience_fees:parseFloat(convenienceFee.toFixed(decimal))
    }
     
}
