// actions.js

export const SET_PAID_WAIT_TOTAL_TIME = 'SET_PAID_WAIT_TOTAL_TIME';
export const SET_PAID_STAYING_ACTIVE = 'SET_PAID_STAYING_ACTIVE';

export const setPaidWaitTotalTime = (time) => ({
    type: SET_PAID_WAIT_TOTAL_TIME,
    payload: time,
});

export const setPaidStayingActive = (isActive) => ({
    type: SET_PAID_STAYING_ACTIVE,
    payload: isActive,
});
